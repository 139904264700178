const slotsProviderSubstitutionStatistic = {
  'gitslotpark': 'PragmaticNew',
};

const slotsProviderSubstitution = {
  'gitslotpark': 'PRAGMATIC-NEW',
};

export {
  slotsProviderSubstitution,
  slotsProviderSubstitutionStatistic,
}